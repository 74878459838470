import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Col, Row } from 'react-styled-flexboxgrid'

import Background from '../components/Background'
import Social from '../components/Social'
import { colors, device } from '../styles/config'

const ContactPage = ({ data: { datoCmsContact: { info, text } } }) => {
  return (
    <>
      <Background openTop>
        <Container>
          <Row>
            <Col xs={12} sm={3}>
              <Header>Kontakt</Header>
            </Col>
            <Text xs={12} sm={4} dangerouslySetInnerHTML={{ __html: info }}/>
            <Text xs={12} sm={5} dangerouslySetInnerHTML={{ __html: text }}/>
          </Row>
          <Social/>
        </Container>
      </Background>
    </>
  )
}

const Container = styled.div`
  ${ props => props.theme.wrapper }
  ${ props => props.theme.paddingTop }
  ${ props => props.theme.backgroundTop }

  h4 {
    &:before {
      display: block;
      content: '';
      margin-bottom: 20px;
      width: 55px;
      height: 5px;
      background-color: ${ colors.highlight };

      @media ${ device.sm } {
        margin-bottom: 10px;
      }
    }
  }

  ${ Row } {
    margin-bottom: 9.375rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Header = styled.h1`
  text-align: right;

  @media ${ device.sm } {
    text-align: left;
    margin-bottom: 1rem;
  }
`

const Text = styled(Col)`
  p {
    margin-bottom: 1rem;
  }
`

export const query = graphql`
  query ContactPage {
    datoCmsContact {
      info
      text
    }
  }
`

export default ContactPage
